#rental{
    
    max-width:fit-content;
    margin:auto;
    margin-bottom:90px;
    
    .small-euro-symbol{

        font-size:0.8em;
    }
        
    #rental-large{
                  
        table{

            width:100%;
            border: 1px solid var(--border-color);
            border-radius:var(--border-radius);
            padding: 10px;
            background: var(--white-transparent);
        }

        th{

            padding:5px 20px;
        }

        #days-label{
        
            padding-left:0px;
            text-align:left;
        }
        
        td{

            text-align:center;
            padding:10px 0px;
        }

        .category{

            padding-top:20px;
            padding-bottom:10px;
            font-weight:bold;
            text-align:left;
        }

        .title{

            padding-right:20px;
            text-align:left
        }        
    }
    
    #rental-mobile{
        
        display:none;
        
         .category{
                
            font-weight:bold;
            font-size:1.2em;
            text-align:center;
             text-transform: uppercase;
             margin-bottom:20px;
        }
            
        .prices-grid{
            
            display:flex;
            gap:50px;
            justify-content: center;        
            flex-wrap: wrap;
            margin-left: 15px;
            margin-right: 15px;
            
            table{
                
                width: 130px;
                margin-top: 25px;
                border: 1px solid var(--border-color);
                border-radius:var(--border-radius);
                padding: 10px;
                background: var(--white-transparent);
            }
            
            caption{
                
                margin-bottom: 10px;
                text-align: center;
                text-transform: uppercase;   
            }
            
            td{
                
                text-align:center;
                padding:10px 20px;
            }
        
                
        }
    }
}
