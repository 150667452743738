#virtual-visit{
        
    #icon-360{
        
        width:fit-content;
        margin:auto;   
        margin-bottom:10px;
    }
    
    #frame-container{
        
        max-width:900px;
        margin:auto;
        margin-bottom:90px;
        padding-left:20px;
        padding-right:20px;
        
        #frame{
            
            width:100%;
            height:500px;
            border:0
        }
    }
}
