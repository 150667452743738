#google-maps{
             
    z-index: 3;
    position:relative;
    
    .h1-with-icon{
        
        display:block;
        
        a{
            
            background: #4d96f7;
            color: white;
            font-size: 0.6em;
            font-weight: normal;
            border: 1px solid;          
            display:block;
            padding:10px;
            width:fit-content;
            margin:auto;
            margin-top: 10px;
            border-radius:5px;
        }
        
        a:hover{
            
            background:#6cabff
        }
    }
    
    #address{
               
        margin: auto;
        width: 400px;
        gap: 10px;
        margin-bottom: 90px;
        padding: 20px;
        background: var(--white-transparent);
        border: 1px solid var(--border-color);
        border-radius: 8px;
        line-height: 25px;
        
        #postal{
                       
            margin-bottom:20px;
        }
        
        #contact{
            
            .entry{
                
                display:flex;
                gap:10px;
                align-items: center;
            }
        }
        
        #open-hours{
            
            margin-top:20px;
            
            .entry:first-of-type{
                
                margin-bottom:10px;
            }
        }
    }
}
