#brands{
    
    .logos-container{
        
        max-width:610px;
        margin:auto;
        padding:0px 10px;
    }
    
    .logos{

        display:flex;
        
        flex-wrap: wrap;

        gap: 30px;
        align-content: space-between;
        justify-content: center;
        align-items: center;

        margin-bottom:90px;
        border: 1px solid var(--border-color);
        border-radius:var(--border-radius);
        padding: 20px;
        background-color: var(--white-transparent);        

        img{

            max-height:35px;
        }

        #capita{    width:115px}
        #dc{        width:44px}
        #vans{      width: 80px}
      
     
        #dakine{    width:150px}


        #eivy{width:80px}
        #nixon{width:135px}
        #dainese{width:140px}

        
        #smith{width:110px}
        #oakley{width:110px}
       
    }
}