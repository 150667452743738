#footer {
    
    position: relative;
    overflow: hidden;
    
    #wood-plank{
        
        height:341px;
        position:relative;
    }
    
    #black-snow{
        
       top: 230px;
    }
    
    #bottom-header{
        
        height: 198px;   
        position: absolute;
        z-index: 2;
        top: -150px;
        left: -15px;    
        border-top-width: 0px;
            transform: rotate(1deg);
    }
}
