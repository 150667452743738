:root  {
   
  --border-color: #dbdbdb;
  --input-color:#626262;
  --gray:rgb(76 76 76);
  --white-transparent:rgba(255, 255, 255, 0.6);
  --border-radius:8px
}    

*{
    
    font-family: 'League Spartan', sans-serif;
    font-size:1em;
    box-sizing: border-box;
    
}


body, html {
    
    margin: 0px;
    padding: 0px;
    height: 100%;
    color:#4c4c4c;
}

body {
    
    display: flex;
    flex-direction: column; 
    background:white;
    background-image:url("../public/pics/background-body-white-snow.jpg");
}

header{
    
    overflow:hidden;
}

#black-snow{
    
    background-image:url("../public/pics/background-black-snow.png");
    height: 194px;
    transform: rotate(357deg);
    position: absolute;
    width: 110%;
    top: -75px;
    left: -20px;
}

#wood-planks{
    
    background-image:url("../public/pics/wood-planks.png");
    height: 320px; 
    position: relative;
    
}

#logo{
    
    background-image:url("../public/pics/blackcats-snowboard-shop-logo.png");    
    height: 140px;
    width: 106px;
    top: 30px;
    margin: auto;
    position: relative;
    background-size: cover;
    z-index: 2;   
}

#bottom-header{
    
    background: white;
    width: 110%;
    height: 133px;
    transform: rotate(2deg);
    position: relative;
    top: 99px;
    left: -15px;
    border: 10px solid rgb(76 76 76);
    background-image:url("../public/pics/background-body-white-snow.jpg");
}

label{
    
    display:block;
    margin-bottom:25px;
    
    .title{
        
        margin-bottom:10px;
    }
}

input[type=text]{

    padding: 8px;
    border-radius: 3px;
    border: 1px solid gray;
    color:var(--input-color);
}

h1{
    
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 35px;
    text-transform: uppercase;            
}

.h1-with-icon{
    
    display:flex;
    gap:15px;
    align-items:center;
    justify-content: center;
}

button{
    
    padding:8px;
    cursor:pointer;
}

.blue-button{
    
    background: #6dd7f9;
    border: 1px solid #2fa0c9;
    position:relative;
    color:#006793
}

.blue-button:active{
    
    top: 1px;
}

.blue-button:hover{
    
    background:#88e2ff
}

a{
    color:#156493;
    text-decoration:none;
}

a:hover{
    
    color:#71c6f9
}

#root {
    
    display: flex;
    flex-direction: column;
    flex: 1;
}

footer {
    
    background: #f1f1f1;   
    text-align: center;
    
}


@media screen and (max-width: 950px) {

    #rental-large{
        
        display:none
    }
    
    #rental-mobile{
        
        display:block !important;
    }
}

@media screen and (max-width: 620px) {

    #google-maps{
        
        flex-flow: column-reverse;
    }
    
    #map-link{
        
        margin:auto
    }
}