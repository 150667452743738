#admin{

    #auth-panel{
        
        width:400px;
        padding:25px;
        border:1px solid var(--border-color);
        margin:auto;
        
        input{
            
            width:100%;
        }
        
        button{
            
            width:100%;
            margin-top:20px;
        }
    }
    
    input[type=file]{
    
        visibility:hidden
    }
    
    section{
        
        width:900px;
        margin:auto
    }
    
    #menu{
        
        margin-bottom:60px;
    }
    
    #reached-limit{
    
        padding-left: 30px;
    }
    
    .pictures{
        
        display:flex;
        gap:30px;
        flex-flow: wrap;
        margin-top:30px;
        
        .picture{
            
            text-align: center;
            padding:10px;
            border:1px solid var(--border-color);
            background: var(--white-transparent);
            
            img{
                
                padding-bottom:10px
            }
        }
    }   
}

 
    #admin-rental{
        
        max-width: 950px;
        margin: auto;
    
        .price{
            
            width:50px;
        }
        
        .category{
            
            padding:15px 0px;
            font-weight:bold
        }
        
        .title{
            
            padding-right:20px;
        }
    }