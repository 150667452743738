#pictures{
    
    margin: 0px 20px;
    
    #container{
            
        position: relative;       
        border:1px solid var(--border-color);  
        padding:10px;
        background:white;
        z-index:1;
        margin:auto;
   
        img{
            
            display:block;;
            width:100%;
            transition: opacity 250ms
        }
    }
    
    #preload{
        
        display:none
    }
    
    #panel{
        
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: fit-content;
        background: var(--gray);
        transform: rotate(3deg);
        margin: auto;
        height: 50px;
        position: relative;
        top: -10px;   
        z-index:0;
        
        .button{
            
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: white;
            margin: 8px;
            cursor: pointer;
        }
        
        .button:hover{
        
            opacity:0.3
        }
        
        .selected{
            
            opacity:0.3
        }
    }
}
